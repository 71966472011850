@import url(https://fonts.googleapis.com/css?family=Kaushan+Script|Merriweather);
html,
body,
#tarot-widget {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: default;
}

#tarot-widget {
  font-family: 'Kaushan Script', cursive;
  font-family: 'Merriweather', serif;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f0f0f0;
}

